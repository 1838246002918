import { Fragment } from "react";
import Skeleton from "@mui/material/Skeleton";
import { Grid, Typography, Box, Divider, Card } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

import { useAuth } from "../../hooks/AuthContext";
import useStyles from "./styles";

import CancelarPedidoCambio from "../CancelaPedidoCambio";
import FormasPagamentoSelecionado from "../PedidoConcluidoPagamentoEscolhido";
import TipoStatusPedido from "../../util/typesTipoStatusPedido";

import { format } from "date-fns";

import { formatMoneyInStringForm } from "../../util/helpers";

import { useTheme } from "@mui/styles";
import Subtitulo from "../Textos/Subtitulo";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "transparent" : "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(0.5),
  textAlign: "center",
  boxShadow: "none",
  fontSize: 15,
  color: theme.palette.text.secondary,
}));

export default function DetalhePedidoChipInternacional({
  dadosPedido,
  isLoading,
}) {
  const { user } = useAuth();

  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      <Grid sx={{ background: '#fcfcfc', margin: 'auto' }} className={classes.Box} item xs={12} lg={12}>
        <Grid>
          {isLoading &&
            [...Array(3)].map((_, index) => (
              <Skeleton width="auto" key={index} height={120} sx={{ mt: -4 }} />
            ))}
          {!isLoading && (
            <List sx={{ width: "100%" }}>
              <Box>
                <Box>
                  <Typography
                    variant="h5"
                    sx={{ textAlign: "left", fontWeight: "bold", mt: 2 }}
                  >
                    Detalhes do pedido
                  </Typography>
                  <Typography variant="h6" sx={{ textAlign: "left", mt: 2 }}>
                    Pedido{" "}
                    <span className={classes.Codigo}> #{dadosPedido?.id}</span>
                  </Typography>

                  <Typography variant="body1" className={classes.Text}>
                    Realizado em{" "}
                    {format(new Date(dadosPedido?.dataCadastro), "dd/MM/yyyy")}
                  </Typography>

                  <Typography variant="body1" className={classes.Text}>
                    Status do pedido: {dadosPedido?.status?.descricao}
                  </Typography>

                  {/* CASO CANCELADO */}
                  {dadosPedido?.status &&
                    dadosPedido?.status.id === TipoStatusPedido.Cancelado && (
                      <List
                        sx={{
                          width: "100%",
                          pb: 0,
                          pt: 1,
                        }}
                      >
                        <ListItem disablePadding>
                          <ListItemText>
                            <Typography color="error">
                              Motivo do cancelamento:
                            </Typography>
                            {dadosPedido?.motivoCancelamento}
                          </ListItemText>
                        </ListItem>
                      </List>
                    )}

                  <Typography
                    variant="h4"
                    sx={{
                      marginBottom: 1,
                      fontWeight: "bold",
                    }}
                  ></Typography>
                </Box>
                {/* {dadosPedido?.status.id === 1 && (
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <CancelarPedidoCambio
                      idPedido={dadosPedido?.id}
                      idCliente={user.id}
                    />
                  </Box>
                )} */}
                <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid sx={{ width: "100%" }} item lg={12}>
                    <Item>
                      <Box className={classes.ItensPedido}>
                        {dadosPedido?.chipInternacional?.length > 0 &&
                          dadosPedido?.chipInternacional?.map((item, index) => (
                            <Fragment key={item?.id + "chipdet"}>
                              <Typography
                                variant="h4"
                                className={classes.Titulo}
                              >
                                Chip Selecionado - {item.nome}
                              </Typography>
                              <Typography className={classes.Text}>
                                {" "}
                                Pacote de dados: {item.tamanho}GB
                              </Typography>
                              <Typography className={classes.Text}>
                                {" "}
                                Quantos dias: {item.dias}
                              </Typography>
                              <Typography className={classes.Text}>
                                {" "}
                                Pais: {item.pais}
                              </Typography>
                            </Fragment>
                          ))}
                      </Box>
                    </Item>

                    {dadosPedido?.status &&
                      dadosPedido?.status.id ===
                      TipoStatusPedido.PagamentoIdentifica && (
                        <Item>
                          <List className={classes.formaPagamento}>
                            <Typography className={classes.Titulo}>
                              Ative seu chip
                            </Typography>
                            <Divider sx={{ mt: 1, mb: 3 }}></Divider>
                            <Card className={classes.cardContainer}>
                              <Box className={classes.qrCodeContainer}>
                                {!!dadosPedido?.chipInternacional[0]
                                  ?.qrCodeBase64 ? (
                                  <>
                                    <Subtitulo sx={{ color: "#060606" }}>
                                      Use o QR code para ativar o seu chip
                                    </Subtitulo>
                                    <br />
                                    <img
                                      className={classes.qrCode}
                                      alt="Pix"
                                      src={
                                        dadosPedido?.chipInternacional[0]
                                          .qrCodeBase64
                                      }
                                      loading="lazy"
                                    />
                                    <br />
                                    <Subtitulo
                                      sx={{
                                        color: "#060606",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Obs.: Caso haja problemas com a ativação
                                      entrar em contato com:
                                      <Typography
                                        sx={{
                                          fontWeight: "bold",
                                          cursor: "pointer !important",
                                        }}
                                        onClick={(e) => {
                                          window.location.href =
                                            "mailto:contact@ezsimconnect.com";
                                        }}
                                      >
                                        contact@ezsimconnect.com
                                      </Typography>
                                    </Subtitulo>
                                  </>
                                ) : (
                                  <Subtitulo
                                    sx={{ color: "#060606", mt: 1, mb: 1 }}
                                  >
                                    Estamos gerando o QR Code, por favor
                                    aguarde!
                                  </Subtitulo>
                                )}
                              </Box>
                            </Card>
                          </List>
                        </Item>
                      )}

                    <Item>
                      <List className={classes.formaPagamento}>
                        <Typography className={classes.Titulo}>
                          Forma de pagamento
                        </Typography>

                        <ListItem sx={{ margin: 'auto' }}>
                          <ListItemText>
                            <FormasPagamentoSelecionado
                              margin="auto"
                              pedido={dadosPedido}
                              isTodosDetalhes={false}
                            />
                          </ListItemText>
                        </ListItem>
                      </List>
                    </Item>
                  </Grid>
                  <Grid item lg={12} sx={{ width: "100%" }}>
                    <Item sx={{ paddingBottom: "50px", background: "#fcfcfc" }}>
                      <Box sx={{ pl: 1 }} className={classes.ItensPedido}>


                        <List>
                          <ListItem
                            style={{ borderTop: "1px solid #ccc" }}
                            secondaryAction={
                              <ListItemText
                                primary={
                                  <Typography
                                    color="black"
                                    sx={{
                                      fontWeight: "bold",
                                      fontSize: 20,
                                      mt: 4,
                                    }}
                                  >
                                    {"R$ " +
                                      formatMoneyInStringForm(
                                        dadosPedido?.valorTotal,
                                        2
                                      )}
                                  </Typography>
                                }
                              />
                            }
                            disablePadding
                          >
                            <ListItemText
                              primary={
                                <Typography
                                  color="black"
                                  sx={{
                                    fontWeight: "bold",
                                    fontSize: 20,
                                    mt: 4,
                                  }}
                                >
                                  Valor Total:
                                </Typography>
                              }
                            ></ListItemText>
                          </ListItem>
                        </List>
                      </Box>
                    </Item>
                  </Grid>
                </Grid>

                {/* TODO: sumir com este botão caso o pedido já esteja cancelado, ainda nao foi retirado para poder arrumar layout */}
              </Box>
            </List>
          )}
        </Grid>
      </Grid>
    </>
  );
}
