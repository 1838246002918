import CellWifiIcon from "@mui/icons-material/CellWifi";

import CircleIcon from "@mui/icons-material/Circle";
import SimCardIcon from '@mui/icons-material/SimCard';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Hidden,
  Skeleton,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import {
  BreadCrumbsPaginas,
  ButtonAcaoGrande,
  Modal,
  PaginasMenuDashboard,
  Subtitulo,
  Titulo,
} from "../..";
import {
  addItemVitrine,
  getVitrine,
} from "../../../api/Carrinho/ChipInternacional";
import { useAuth } from "../../../hooks/AuthContext";
import useAlert from "../../../hooks/useAlert";
import { formatMoneyInStringForm } from "../../../util/helpers";
import { GETVITRINECHIP } from "../../../util/typesReactQuery";
import MenuDashboard from "../../MenuDashboard";
import Seletor from "../../Seletor";
import { Style } from "./styles";
import { pink } from "@mui/material/colors";
import ButtonAcaoGrandeOutlined from "../../Botoes/ButtonAcaoGrande copy";
import { ButtonCustom } from "../../Botoes/Button";
import AlertCustomFixed from "../../AlertCustomFixed";

const breadCrumbs = [
  {
    label: "Chip Internacional",
    icon: <CellWifiIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
  },
  {
    label: "Compra de Chip Internacional",
    icon: <SimCardIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
  },
];

function VitrineChip() {
  const [vitrine, setVitrine] = useState();
  const [vitrineFiltrada, setVitrineFiltrada] = useState([]);
  const [quantidade, setQuantidade] = useState(1);
  const [paises, setPaises] = useState([]);
  const [selectedPais, setSelectedPais] = useState(1);
  const [selectedChip, setSelectedChip] = useState(null);
  const [isLoading, setIsloading] = useState(true);
  const classes = Style();
  const { user } = useAuth();
  const { showAlertError, showAlertSuccess, showAlertInfo } = useAlert([]);
  const theme = useTheme();
  const navigate = useNavigate();
  const [isVisibleModalCompaat, setIsVisibleModalCompat] = useState(false);

  function createPais(array) {
    let newArray = [];
    let newSet = new Set();
    let index = 1;
    array.forEach((item) => {
      newSet.add(item.pais);
    });

    for (let item of newSet.values()) {
      newArray.push({ id: index, descricao: item });
      index++;
    }

    return newArray;
  }

  const filtrarVitrine = () => {
    if (vitrine?.length > 0 && paises.length > 0) {
      let paisSelecionado = paises.find((item) => item.id === selectedPais);
      let filtro = vitrine?.filter(
        (item) => item.pais === paisSelecionado.descricao
      );
      setVitrineFiltrada(() => [...filtro]);
      setSelectedChip(() => ({ ...filtro[0] }))
    }
  };

  const { mutateAsync: comprarChip } = useMutation({
    mutationFn: addItemVitrine,
    onSuccess: (data) => {
      if (data?.errorMessage) {
        showAlertError(`Ops, algo deu errado: ${data?.errorMessage} - R3C03`);
        return;
      }

      showAlertSuccess("Oba, seu chip foi salvo no carrinho.");
      setTimeout(() => {
        navigate("/ChipInternacional/Pagamento");
      }, 1500);
    },
    onError: (error) => {
      showAlertError(
        "Ops, algo deu errado: " + error?.message ?? error + " - RXZTQ"
      );
    },
    onSettled: () => {
      setTimeout(() => {
        setIsloading(false);
      }, 1500);
    },
  });

  useQuery({
    queryKey: [GETVITRINECHIP],
    queryFn: getVitrine,
    onSuccess: (data) => {
      if (data?.errorMessage) {
        showAlertError(`Ops, algo deu errado: ${data.errorMessage}`);
      }
      if (data?.data) {
        let newArray = createPais(data.data);
        setVitrine(() => [...data.data]);
        setPaises(() => [...newArray]);
      }
    },
    onError: (error) => {
      showAlertError(error?.message ?? error);
    },
    onSettled: () => {
      setIsloading(false);
    },
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (vitrine?.length > 0 && !!selectedPais) {
      setIsloading(true);
      setTimeout(() => setIsloading(false), 500);
      filtrarVitrine();
    }
  }, [vitrine, selectedPais]);

  const handlerChangePais = (value) => {
    setSelectedPais(value);
  };

  const handlerSelectedChip = (event, chip) => {
    setSelectedChip(() => ({ ...chip }));
  };

  const handlerComprarChip = async () => {
    setIsloading(true);

    if (!selectedChip || !selectedChip?.id) {
      showAlertError("Ops, primeiro você deve selecionar um chip!");
      setIsloading(false);
      return;
    }

    let dados = selectedChip;
    dados.idCliente = user.id;
    dados.quantidade = quantidade;
    await comprarChip(dados);
  };

  const handlerOpenModalCompat = () => {
    setIsVisibleModalCompat(() => true)
  }

  return (
    <MenuDashboard pagina={PaginasMenuDashboard.ChipInternaciona}>
      <Hidden smUp>
        <Box sx={{ pl: 2 }}>
          <BreadCrumbsPaginas list={breadCrumbs} />
        </Box>
      </Hidden>

      <Hidden smDown>
        <Box sx={{ pt: 2 }}>
          <BreadCrumbsPaginas list={breadCrumbs} />
        </Box>
      </Hidden>

      <Grid
        container
        xs={12}
        sx={{
          width: "100%",
          display: "column",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {isLoading ? (
          <>
            <Grid xs={12} md={6} lg={6}>
              <Skeleton animation="wave" height={60} />
            </Grid>

            <Grid
              container
              xs={12}
              md={7}
              spacing={3}
              lg={7}
              className={classes.containerChip}
            >
              <Grid item xs={12}>
                <Skeleton animation="wave" height={50} />
              </Grid>

              {Array.from({ length: 6 }, (_, index) => (
                <Grid item spacing={4} xs={4} key={index}>
                  <Skeleton sx={{ borderRadius: 4 }} animation="wave" height={250} />
                </Grid>
              ))}
            </Grid>
          </>
        ) : (
          <>
            <Grid
              container
              xs={12}
              md={7}
              lg={7}
              className={classes.containerChip}
            >
              <Grid
                md={12}
                lg={12}
                sx={{ pl: 2, pr: 2, pt: 2 }}
                className={classes.SeletorPais}
              >
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  {paises.find((pais) => pais.id === selectedPais)?.descricao}
                </Typography>
                <Seletor
                  className={classes.Seletor}
                  // isLoading={isLoading}
                  setSelect={handlerChangePais}
                  list={paises}
                  size="medium"
                  key={"pais"}
                  label="Selecione o pais"
                  value={selectedPais}
                />
              </Grid>
              {vitrineFiltrada?.length > 0 ? (
                <>
                  <Grid xs={12} />
                  <Grid sx={{ p: 2, mt: 2 }} xs={12}>
                    <Titulo sx={{ fontSize: 20 }}>
                      Antes de continuar, confira se o seu aparelho é <ButtonCustom sx={{ fontSize: 'inherit', textTransform: 'lowercase' }} variant="text" onClick={handlerOpenModalCompat}>compatível</ButtonCustom>
                      <br />
                      Escolha a duração da sua viagem e o pacote desejada.
                    </Titulo>
                  </Grid>
                  <Grid container sx={{ alignItems: "center" }}>
                    {vitrineFiltrada?.map((item, index) => (
                      <Grid sx={{ p: 2, alignItems: 'cemter' }} xs={12} md={4} lg={4}>
                        {selectedChip?.id === item?.id ? (
                          <>
                            <Box className={classes.IconChip}>

                              <SimCardIcon sx={{ color: "white" }} color="white" ></SimCardIcon>
                            </Box>
                            <Card
                              onClick={(e) => handlerSelectedChip(e, item)}
                              variant="outlined"
                              className={
                                selectedChip?.id === item?.id
                                  ? classes.selected
                                  : classes.card
                              }
                              sx={{ minHeight: "10rem", boxSizing: "border-box" }}
                            >
                              <CardContent>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    mb: ".3rem",
                                  }}
                                >
                                  <Titulo
                                    className={classes.Dias}
                                    sx={{ fontSize: "1.5rem" }}
                                  >
                                    {item?.dias} dias
                                  </Titulo>

                                </Box>

                                <Box sx={{ mt: 3 }}>
                                  <Subtitulo className={classes.Dados}>
                                    Dados
                                    <Typography
                                      className={classes.Dados}
                                      sx={{ fontWeight: "700 !important", ml: 1 }}
                                    >
                                      {" "}
                                      {item?.tamanho}
                                    </Typography>
                                  </Subtitulo>
                                </Box>

                                <Typography className={classes.textoDetalhes}>
                                  <CircleIcon
                                    sx={{
                                      mr: 1,
                                      fontSize: 10,
                                      color: `${theme.palette.primary.main}`,
                                    }}
                                  ></CircleIcon>
                                  {` `} Detalhes
                                </Typography>

                                <Box className={classes.Valor}>
                                  <Typography sx={{ fontSize: 15, mr: 1 }}>
                                    R${" "}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      letterSpacing: "0.1rem",
                                      fontSize: "1.8rem",
                                      fontWeight: "bold",
                                      opacity: 0.9,
                                    }}
                                  >
                                    {formatMoneyInStringForm(item?.valorBRL)}
                                  </Typography>
                                </Box>

                                {vitrineFiltrada?.map((item, index) => (
                                  <>
                                    {selectedChip?.id === item.id && (
                                      <>
                                        <Grid
                                          container
                                          sx={{
                                            alignItems: "center",
                                            mt: 2,

                                            justifyContent: "space-betweeen",
                                          }}
                                        >
                                          <Box className={classes.Box} sx>
                                            <ButtonAcaoGrande
                                              Handle={handlerComprarChip}
                                              disabled={
                                                vitrineFiltrada.length <= 0
                                              }
                                            >
                                              Comprar Chip
                                            </ButtonAcaoGrande>
                                          </Box>
                                        </Grid>
                                      </>
                                    )}
                                  </>
                                ))}
                              </CardContent>
                            </Card>
                          </>
                        ) : (
                          <Card
                            onClick={(e) => handlerSelectedChip(e, item)}
                            variant="outlined"
                            className={
                              selectedChip?.id === item?.id
                                ? classes.selected
                                : classes.card
                            }
                            sx={{ minHeight: "10rem", boxSizing: "border-box" }}
                          >
                            <CardContent>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  mb: ".3rem",
                                }}
                              >
                                <Titulo
                                  className={classes.Dias}
                                  sx={{ fontSize: "1.5rem" }}
                                >
                                  {item?.dias} dias
                                </Titulo>
                              </Box>

                              <Box sx={{ mt: 3 }}>
                                <Subtitulo className={classes.Dados}>
                                  Dados
                                  <Typography
                                    className={classes.Dados}
                                    sx={{ fontWeight: "bold !important", ml: 1 }}
                                  >
                                    {item?.tamanho}
                                  </Typography>
                                </Subtitulo>
                              </Box>

                              <Typography className={classes.textoDetalhes}>
                                <CircleIcon
                                  sx={{
                                    mr: 1,
                                    fontSize: 10,
                                    color: `${theme.palette.primary.main}`,
                                  }}
                                ></CircleIcon>
                                {` `} Detalhes
                              </Typography>

                              <Box className={classes.Valor}>
                                <Typography sx={{ fontSize: 15, mr: 1 }}>
                                  R${" "}
                                </Typography>
                                <Typography
                                  sx={{
                                    letterSpacing: "0.1rem",
                                    fontSize: "1.8rem",
                                    fontWeight: "bold",
                                    opacity: 0.8,
                                  }}
                                >
                                  {formatMoneyInStringForm(item?.valorBRL)}
                                </Typography>
                              </Box>
                            </CardContent>
                          </Card>
                        )}
                      </Grid>
                    ))}
                  </Grid>
                </>
              ) : (
                <>
                  <Box sx={{ mt: 1, textAlign: "center" }}>
                    <Titulo>
                      Não foi possivel encontrar nenhum chip disponivel no
                      momento!
                    </Titulo>
                  </Box>
                </>
              )}
              <Grid
                container
                sx={{ justifyContent: "space-between", alignItems: "center" }}
              ></Grid>
            </Grid>

            <Modal show={isVisibleModalCompaat} close={() => setIsVisibleModalCompat(() => false)} hasButtonClose={true}>
              <Box sx={{ mt: 4 }}>
                <AlertCustomFixed type="info">Ainda não consta nenhum lista disponivel</AlertCustomFixed>
              </Box>
            </Modal>
          </>
        )}
      </Grid>
    </MenuDashboard>
  );
}

export default VitrineChip;
